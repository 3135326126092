import { Injectable } from '@angular/core';
import { Headers } from '@angular/http';
import { Http, Response, HttpModule, RequestOptions } from '@angular/http';
import {ResponseContentType } from '@angular/http';
import { User } from './user';
import { activity } from './activity';
import { AppService } from '../../../app/app.service';
import { saveFile, getFileNameFromResponseContentDisposition } from '../../file-download-helper';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/toPromise';

@Injectable()

export class AUserService {

  aUser: User[]= [];
  activityarray : activity[] = [];
  user_id: any= null;
  user: User= new User;

  constructor(private http: Http, private app_service: AppService) {}



  get_UserList(tenant = 0): Promise<User[]> {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers}); 
    const url = (tenant == 0) ? `${this.app_service.apiUrlUsers}` : `${this.app_service.apiUrlUsers}?tenant_id=${tenant}`;  
    return this.http.get(url, options).toPromise()
    .then(response => response.json() as User[]).catch(response => this.app_service.handleError(response));
  }
  get_status(user_id){
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    const url9 = `${this.app_service.apiUrlUsers}/${user_id}/status`;
    return this.http.get(url9, options).toPromise()
    .then(response => response.json()).catch(response => this.app_service.handleError(response));
  }
  logout(user_id) {
    const headers = new Headers();
    this.app_service.loginAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    const url = `${this.app_service.apiUrl}/authenticate/cancel/${user_id}`;
    let body = JSON.stringify(user_id);
    return this.http.post(url, body, options).toPromise().then(response => response.json())
  }
  authenticate(user_id) {
    const headers = new Headers();
    this.app_service.loginAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    const url = `${this.app_service.apiUrl}/authenticate/cancel/${user_id}`;
    let body = JSON.stringify(user_id);
    return this.http.post(url, body, options).toPromise().then(response => response.json())
  }
  get_TenantList() {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    return this.http.get(this.app_service.apiUrlTenants, options).toPromise()
    .then(response => response.json()).catch(response => this.app_service.handleError(response));
  }

  get_TenantData(tenant_id) {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    const url5 = `${this.app_service.apiUrlTenants}/${tenant_id}`;
    return this.http.get(url5, options).toPromise()
    .then(response => response.json()).catch(response => this.app_service.handleError(response));
  }

  get_RoleList() {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    return this.http.get(this.app_service.apiUrlRoles, options).toPromise()
    .then(response => response.json()).catch(response => this.app_service.handleError(response));
  }

  setRole(user_id, role_id) {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({headers: headers});
    const body = JSON.stringify(user_id);
    const setRoleUrl = `${this.app_service.apiUrlUsers}/${user_id}/roles/${role_id}`;
    return this.http.put(setRoleUrl, body, options).toPromise().then(response => response.json())
    .catch(response => this.app_service.handleError(response));
  }

  getUserRoles(user_id) {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    const url9 = `${this.app_service.apiUrlUsers}/${user_id}/roles`;
    return this.http.get(url9, options).toPromise()
    .then(response => response.json()).catch(response => this.app_service.handleError(response));
  }

  deleteRoles(user_id, role_id) {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({headers: headers});
    const deleteRoleUrl = `${this.app_service.apiUrlUsers}/${user_id}/roles/${role_id}`;
    return this.http.delete(deleteRoleUrl, options).toPromise().then(response => response.json())
    .catch(response => this.app_service.handleError(response));
  }

  get_UserData(user_id): Promise<User> {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    const url5 = `${this.app_service.apiUrlUsers}/${user_id}`;
    return this.http.get(url5, options).toPromise()
    .then(response => response.json() as User).catch(response => this.app_service.handleError(response));
  }

  add_User(user: User): Promise<User> {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({headers: headers});
    const body = JSON.stringify(user);
    const addUrl = `${this.app_service.apiUrlUsers}`;
    return this.http.post(addUrl, body, options).toPromise().then(response => response.json() as User)
    .catch(response => this.app_service.handleError(response));
  }

  update_User(user: User): Promise<User> {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({headers: headers});
    const body = JSON.stringify(user);
    const updateUrl = `${this.app_service.apiUrlUsers}/${user.user_id}`;
    return this.http.put(updateUrl, body, options).toPromise().then(response => response.json() as User)
    .catch(response => this.app_service.handleError(response));
  }

  delete_User(user_id): Promise<any> {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({headers: headers});
    const deleteUrl = `${this.app_service.apiUrlUsers}/${user_id}`;
    return this.http.delete(deleteUrl, options).toPromise().then(response => response.json() as User)
    .catch(response => this.app_service.handleError(response));
  }

  changePass(user) {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({headers: headers});
    const body = JSON.stringify(user);
    const updateUrl = `${this.app_service.apiUrl}/password/users`;
    return this.http.put(updateUrl, body, options).toPromise().then(response => response.json())
    .catch(response => this.app_service.handleError(response));
  }

  get_UserToken(user_id): Promise<User> {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    const url5 = `${this.app_service.apiUrlUsers}/${user_id}/token`;
    return this.http.get(url5, options).toPromise()
    .then(response => response.json() as User).catch(response => this.app_service.handleError(response));
  }

  public get_Timezone() {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    return this.http.get(this.app_service.apiUrlTimezone, options).toPromise()
    .then(response => response.json()).catch(response => this.app_service.handleError(response));    
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }

  getActivity(user_id): Promise<activity[]> {
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    const url5 = `${this.app_service.apiUrlUsers}/activity/${user_id}`;
    return this.http.get(url5, options).toPromise()
    .then(response => response.json() as activity[]).catch(response => this.app_service.handleError(response));
    
  }
  get_ActivitiesList(filter) {
    const tenant_id = parseInt(localStorage.getItem('tid'));
    const user_id = parseInt(localStorage.getItem('aid'));
    const is_admin = parseInt(localStorage.getItem('is_admin'));
    const is_tenant = parseInt(localStorage.getItem('is_tenant'));
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    let geturl = `${this.app_service.apiUrlUsers}/activities/${user_id}?${filter}`;
    // if (filter) geturl += filter;
    if (is_admin == 0 && is_tenant == 1) { geturl += `&tenant_id=${tenant_id}`; }
    return this.http.get(geturl, options).toPromise()
    .then(response => response.json() ).catch(response => this.app_service.handleError(response));
  }

  exportact(filter = null) {
    const tenant_id = parseInt(localStorage.getItem('tid'));
    const user_id = parseInt(localStorage.getItem('aid'));
    const is_admin = parseInt(localStorage.getItem('is_admin'));
    const is_tenant = parseInt(localStorage.getItem('is_tenant'));
    const headers = new Headers();
    this.app_service.createAuthorizationHeader(headers);
    const options = new RequestOptions({ headers: headers});
    options.responseType = ResponseContentType.Blob;
    let url = `${this.app_service.apiUrlexportact}/${user_id}?${filter}`;
   
    if (is_admin == 0 && is_tenant == 1) { url += `&tenant_id=${tenant_id}`; }
    this.http.get(url, options).subscribe(res => {
      const fileName = getFileNameFromResponseContentDisposition(res);
      console.log(res);
      saveFile(res.blob(), fileName);
    }, error => {
      this.app_service.downloadError(error)
    });
  }

}
