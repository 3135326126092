import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BrandingService } from '../../../pages/branding/branding.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { AppService } from '../../../app.service';
import { AUserService } from '../../../pages/user/user.service';
import { InFaxService } from '../../../pages/infax/infax.service';
import { IntervalObservable } from 'rxjs/observable/IntervalObservable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SessionComponent } from '../../../pages/session.component';
import { PasswordPolicy_Service } from '../../../pages/password_policy/password_policy.service';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  status:any ;
  private subscription: Subscription //import from rxjs 
  domain_title: string = 'ICTFax';
  auser: any;
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: this.translate.instant('general.change_pass'), link: '/pages/Changepass' }, { title: this.translate.instant('general.log_out'), link: 'auth/logout' }];

  image_url = "../../../../assets/images/ictfax-logo.png";
  inbound_faxs: any = 0;

  startTime: Date;
  sessionTime: number = 0;
  ExpiredTime: any;
  is_admin: any;
  is_tenant: any;
  isModelopen: boolean = false;

  constructor(private sidebarService: NbSidebarService,
    private infax: InFaxService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService, private authService: NbAuthService,
    private router: Router, private nbMenuService: NbMenuService,
    private user_service: AUserService ,
    private branding_service: BrandingService, public translate: TranslateService,
    private app_service: AppService,
    private modalservice: NgbModal,
    private passwdPolicy: PasswordPolicy_Service) {

    this.startTime = new Date();
    this.authService.onTokenChange()
      .subscribe((token: NbAuthJWTToken) => {

        if (token.isValid()) {
          this.auser = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
          localStorage.setItem('username', this.auser.username);
          localStorage.setItem('is_admin', this.auser.is_admin);
          localStorage.setItem('is_tenant', this.auser.is_tenant);
          localStorage.setItem('aid', this.auser.user_id);
          localStorage.setItem('tid', this.auser.tenant_id);
          localStorage.setItem('permission', "");
          localStorage.setItem('MFApermission', this.auser.mfa_enabled);  // tenant MFA permission
          if (this.auser.user_permission) localStorage.setItem('permission', this.auser.user_permission);
          this.get_domain_title(this.auser.tenant_id);
          // const brand:any = this.get_domain_title(this.auser.tenant_id);
          // if (!brand) this.get_domain_title(0);
          this.image_url = `${this.app_service.apiUrlBranding}/${this.auser.tenant_id}/media`;
        }
        else{
          localStorage.clear();
         }

      });
  }

  ngOnInit() {
    IntervalObservable.create(5000)
    .subscribe(() => {
       this.getstatus();
      this.InitializeSession();
    });
    this.currentTheme = this.themeService.currentTheme;

    // this.Notification()

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.subscription = this.nbMenuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if (title == 'Log out') {
          let copy_token = localStorage.getItem('copy_token');
          if (copy_token != null) {
            localStorage.removeItem('copy_token');
            window.location.reload();

            this.authService.getToken()
              .subscribe((token: NbAuthJWTToken,
              ) => {
                if (token && token.getValue()) {
                  let auser = token.getPayload();
                  localStorage.setItem('username', auser.username);
                  localStorage.setItem('is_admin', auser.is_admin);
                }
              });

            this.router.navigate(['/dashboard']);

          }
          else {
            if(localStorage.getItem('MFApermission') == '1') {
              // before logout update user table
              this.auser.verify = 0;
              this.user_service.update_User(this.auser).then(data => {
              });
            }
            this.router.navigate(['auth/logout']);
          }
          sessionStorage.removeItem('refresh');
        }
      });
  }

  InitializeSession() {
    
    this.is_admin = Number(localStorage.getItem('is_admin'));
    this.is_tenant = Number(localStorage.getItem('is_tenant'));
    
    //Get session expire-in time from passwdPolicy
    this.passwdPolicy.get_Policy().then(response=> {
      this.ExpiredTime = response.sessiontime;
    }).catch(error => {
      console.error('Error fetching Sessiontime:', error);
    });
    
    //Get session login time in Minutes
    const currentTime = new Date();
    const diffMs = currentTime.getTime() - this.startTime.getTime();
    this.sessionTime = Math.floor(diffMs / (1000 * 60)); // Convert milliseconds to minutes
    
    //Compare session expire-in and session login time 
    if(this.sessionTime === Number(this.ExpiredTime)) {
      console.log("testing")

      this.startTime = new Date();
      if(!this.is_admin && !this.is_admin) {
         this.modalservice.open(SessionComponent);
         console.log("logoing out")
         this.isModelopen = true;
    
    this.startPopupTimer();
      }
    }
    
    // if(this.isModelopen && this.sessionTime === 1) {
    //   this.modalservice.dismissAll();
    //   localStorage.clear();
    //   window.location.reload();
    // }
  }
  startPopupTimer() {
    const responseTime = 60 * 1000; // 60 seconds
        setTimeout(() => {
      if (this.isModelopen) {  // Check if the popup is still open
        this.modalservice.dismissAll(); // Close the popup
        localStorage.clear(); // Clear the session storage
        this.router.navigate(['auth/logout']); // Redirect to logout
        window.location.reload(); // Optionally reload the page
      }
    }, responseTime);
  }
 
  getstatus(){
    this.user_service.get_status(this.auser.user_id).then(response => {
      this.status = response;
    });
    if(this.status == 'logout'){
    localStorage.clear();
    window.location.reload();
    }
  }
  handleBellIcon() {
    this.router.navigate(['../pages/infax']);
    this.inbound_faxs = 0;
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    this.subscription.unsubscribe();
  }
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    return false;
  }
  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  ngDoCheck() {
    let myname = localStorage.getItem('username');
    if (myname != undefined) {
      this.auser.username = myname;
    }
  }
  get_domain_title(tenant_id) {
    this.branding_service.get_Branding(tenant_id).then(response => {
      document.title = response.title;
      this.domain_title = response.title;
    });
  }
  // Notification() {
  //   this.infax.count().then(response => {
  //     // console.log(response.Inbound_faxs);
  //     this.inbound_faxs = response.Inbound_faxs;

  //   })
  // }
}
